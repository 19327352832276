#quote-section {
  transition: opacity 0.2s linear;
}

.quote-body-a {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../imgs/background1.jpg") no-repeat;
  background-size: cover;
}

.quote-body-b {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../imgs/background2.jpeg") no-repeat;
  background-size: cover;
}

.authorName {
  color: navajowhite;
}

button:focus,
button:hover {
  border: solid 1px lightblue;
}
