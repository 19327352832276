footer {
  position: relative;
  margin-top: -15px;
}

footer h3 {
  color: navajowhite;
}

footer a {
  color: navajowhite;
}

.footer-tab {
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  top: -20px;
  left: 50%;
  margin-left: -40px;
}
